import React from 'react';

import { Dorothy } from 'dorothy-dna-react';

// import { ReactQueryDevtools } from 'react-query/devtools';

import { QueryClient, QueryClientProvider } from 'react-query';

import axios from 'axios';

import tools from './tools';

import Body from './Body';

import { SnackbarProvider } from 'notistack';

const config = {
  app_name: 'isa_sppf',
  server: process.env.REACT_APP_SERVER,
  tools,
};

axios.defaults.headers.common['cache-control'] = 'no-cache';

function Prep() {
  return <strong>...</strong>;
}
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: async ({ queryKey }) => (await axios.get(`${config.server}${queryKey}`)).data,
        retry: false,
        refetchOnWindowFocus: false,
        cacheTime: 0,
      },
    },
  });

  return (
    <div id="App">
      <Dorothy config={config} preparingEl={<Prep />}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <Body />
          </SnackbarProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </Dorothy>
    </div>
  );
}

export default App;
