import create from 'zustand';
import axios from 'axios';

const useConfigStore = create(set => ({
  config: {},
  fetch: async (server)=>{
    const { data } = await axios.get(`${server}general/config`);
    set({ config: data });
  }
}));

export default useConfigStore;
