import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline, GlobalStyles } from '@mui/material';
import './sass/index.scss';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styleguide/theme';
import App from './App';

const client = new QueryClient({ defaultOptions: { queries: { retry: 1, keepPreviousData: true } } });

ReactDOM.render(
  <QueryClientProvider client={client}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ body: { background: theme.palette.beige.gradient } }} />
      <App />
    </ThemeProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
