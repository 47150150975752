/* In React Native Tools must be pre-declared :-( */
import { lazy } from 'react';

const tools = {
  Agora: lazy(() => import('./Agora')),
  Associations: lazy(() => import('./Associations')),
  Communities: lazy(() => import('./Communities')),
  Members: lazy(() => import('./Members')),
  Inflow: lazy(() => import('./Inflow')),
  Producers: lazy(() => import('./Producers')),
  ProducerCommunities: lazy(() => import('./ProducerCommunities')),
  Goods: lazy(() => import('./Goods')),
  Deliveries: lazy(() => import('./Deliveries')),
  Orders: lazy(() => import('./Orders')),
  Products: lazy(() => import('./Products')),
  Supporters: lazy(() => import('./Supporters')),
  Regions: lazy(() => import('./Regions')),
  ShippingCompany: lazy(() => import('./ShippingCompany')),
  CommercialPartner: lazy(() => import('./CommercialPartner')),
  Unity: lazy(() => import('./Unity')),
  Batches: lazy(() => import('./Batches')),
  Outs: lazy(() => import('./Outs')),
  SupporterHome: lazy(() => import('./SupporterHome')),
  Antecipation: lazy(() => import('./Antecipation')),
  PaymentOrders: lazy(() => import('./PaymentOrders')),

  ProductsStock: lazy(() => import('./ProductsStock')),
  GoodsStock: lazy(() => import('./GoodsStock')),

  HelpNotification: lazy(() => import('./notifications/HelpNotification')),
  NewUserNotification: lazy(() => import('./notifications/NewUserNotification')),
  MessageNotification: lazy(() => import('./notifications/MessageNotification')),
};

export default tools;
