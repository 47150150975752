import React, { useEffect } from 'react';

import { useUser, CommunityRouter, useQuery, useDorothy } from 'dorothy-dna-react';

import { Switch, Route, useHistory, Redirect, useLocation } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import LogoSimple from './images/logo-simple.png';
import Logo from './images/logo.png';
import Navbar from './Navbar';
import LoginPanel from './commons/components/Login';

import Welcome from './commons/components/Welcome';

import useConfigStore from './stores/ConfigStore';

export default function Body() {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { server } = useDorothy();
  const { fetch } = useConfigStore(); 

  const { enqueueSnackbar } = useSnackbar();

  const { isLogged, login, doing_login, user } = useUser();

  const handleLogin = async (email, password, next) => {
    const user = await login(email, password);

    if (!user)
      enqueueSnackbar('E-mail ou senha incorretos!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    else history.push(next ? next : '/');
  };

  useEffect(()=>{
    if(server) fetch(server);
  },[server, fetch])

  return (
    <>
      {isLogged && <Welcome user={user} />}

      {isLogged && <Navbar appLogo={LogoSimple} />}

      <Switch>
        <Route path="/login/:screen?/:recover?">
          {!isLogged && (
            <div className="page-wrapper login">
              <LoginPanel onLogin={handleLogin} next={query.get('url')} doing_login={doing_login} appLogo={Logo} />
            </div>
          )}
          {isLogged && <Redirect to="/" />}
        </Route>

        {!isLogged && (
          <Route>
            <Redirect to={`/login/?url=${location.pathname}`} />
          </Route>
        )}

        {isLogged && (
          <Route>
            <div className="page-wrapper">
              <CommunityRouter />
            </div>
          </Route>
        )}
      </Switch>

      <div className="device-not-supported"></div>
    </>
  );
}
