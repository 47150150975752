/* NON DOROTHY */

import { ReactComponent as CRUDs } from '../../components/icons/address-book-solid.svg';
import { ReactComponent as Inventory } from '../../components/icons/boxes-packing-solid.svg';
import { ReactComponent as Groups } from '../../components/icons/users-rectangle-solid.svg';
import { ReactComponent as Flow } from '../../components/icons/message-solid.svg';
import { ReactComponent as Producer } from '../../components/icons/person-digging-solid.svg';
import { ReactComponent as Association } from '../../components/icons/shop-solid.svg';
import { ReactComponent as Community } from '../../components/icons/user-group-solid.svg';
import { ReactComponent as Product } from '../../components/icons/wheat-awn-solid.svg';
import { ReactComponent as Good } from '../../components/icons/box-solid.svg';
import { ReactComponent as Supporter } from '../../components/icons/handshake-angle-solid.svg';
import { ReactComponent as Basket } from '../../components/icons/basket-shopping-light.svg';
import { ReactComponent as Stock } from '../../components/icons/boxes-stacked-solid.svg';
import { ReactComponent as Goods } from '../../components/icons/jar-solid.svg';
import { ReactComponent as Map } from '../../components/icons/map-solid.svg';
import { ReactComponent as Home } from '../../components/icons/house-solid.svg';
import Truck from '../../components/icons/Truck';
import { ReactComponent as Member } from '../../components/icons/user.svg';
import { ReactComponent as Money } from '../../components/icons/money.svg';

const icons = {
  groups: Groups,
  inventory: Inventory,
  flow: Flow,
  cruds: CRUDs,
  producer: Producer,
  association: Association,
  community: Community,
  product: Product,
  good: Good,
  supporter: Supporter,
  order: Basket,
  stock: Stock,
  goods: Goods,
  map: Map,
  member: Member,
  batch: Truck,
  home: Home,
  antecipation: Good,
  money: Money,
};

export default icons;
